$myColor: rgb(111, 145, 111);
$myColor2: rgb(110, 110, 110);
$myColor-Alt: rgb(79, 104, 79);
$myColor-Alt2: rgb(141, 140, 140);
$myColor-Alpha: rgba(111, 145, 111, 0.747);
$myColor-Alpha-2: #d0dbd0;
$myColor-Alpha-3: rgba(112, 146, 112, 0.164);
$myColor-Alpha-Bg: rgba(111, 145, 111, 0.199);

$myTextColor: rgba(0, 0, 0, 0.7);
$myTextColor-Alt: rgb(226, 226, 226);
$myTextColor-Alt-2: rgb(61, 78, 61);
$myTextColor-Secondary: rgb(111, 145, 111);

.bg-system {
  background-color: $myColor !important;
  border-color: $myColor !important;
  &:hover {
    transition: 0.5s;
    background: $myColor-Alt !important;
    border-color: $myColor-Alt !important;
  }
  &:active {
    background-color: $myColor !important;
    border-color: $myColor !important;
  }
  &:focus {
    background-color: $myColor-Alt;
    background: $myColor !important;
    border-color: $myColor !important;
    box-shadow: 0 0 0 0.2rem $myColor-Alpha !important;
  }
}

.bg-system2 {
  background-color: $myColor2 !important;
  border-color: $myColor2 !important;
  &:hover {
    transition: 0.5s;
    background: $myColor-Alt2 !important;
    border-color: $myColor-Alt2 !important;
  }
  &:active {
    background-color: $myColor !important;
    border-color: $myColor !important;
  }
  &:focus {
    background-color: $myColor-Alt;
    background: $myColor !important;
    border-color: $myColor !important;
    box-shadow: 0 0 0 0.2rem $myColor-Alpha !important;
  }
}

.alert-system {
  background-color: $myColor-Alpha-2 !important;
  border-color: $myColor !important;
  color: $myTextColor-Alt-2 !important;
}

.border-system {
  border: 1px solid;
  border-color: $myColor-Alt !important;
}

.border-system-alt {
  border: 2px solid;
  border-radius: 10px;
  border-color: $myTextColor-Secondary !important;
}

.border-system-alpha {
  border-right: 1px solid;
  border-left: 1px solid;
  border-color: $myColor-Alpha-3 !important;
}

.border-system-right-alpha {
  border-right: 1px solid;
  border-color: $myColor-Alpha-3 !important;
}

.text-system {
  color: $myTextColor-Secondary !important;
}

.text-system-secondary {
  color: $myTextColor !important;
}

.text-system-alt {
  color: $myColor-Alt !important;
}

.text-size-minus {
  font-size: 14px;
}
.text-size-minus-12 {
  font-size: 12px;
}

.border-hr {
  border: 1px solid rgba(0, 0, 0, 0.295);
}

.imgDoc {
  max-width: 320px;
  height: 220px;
}

.progressbar-system {
  background-color: $myColor !important;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.3) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0.3) 75%,
    transparent 75%,
    transparent
  ) !important;
}

.header-link a {
  color: $myTextColor !important;
  &:hover {
    color: $myColor !important;
    text-decoration: none !important;
  }
  &:active {
    background-color: $myTextColor-Alt !important;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap");

.ubuntuFont {
  font-family: "Ubuntu", sans-serif;
}

.fc {
  color: $myTextColor-Secondary;
}

.fc a {
  color: $myColor-Alt;
  cursor: pointer;
}

.fc a:hover {
  color: $myTextColor-Secondary;
  cursor: pointer;
}

.fc .fc-button-primary {
  background-color: $myColor !important;
  border-color: $myColor !important;
  &:hover {
    transition: 0.5s;
    background: $myColor-Alt !important;
    border-color: $myColor-Alt !important;
  }
  &:active {
    background-color: $myColor !important;
    border-color: $myColor !important;
  }
  &:focus {
    background-color: $myColor-Alt;
    background: $myColor !important;
    border-color: $myColor !important;
    box-shadow: 0 0 0 0.2rem $myColor-Alpha !important;
  }
}

.fc .fc-daygrid-day-frame {
  cursor: pointer;
}

.fc .fc-day-today {
  background-color: $myColor-Alpha-Bg !important;
}
