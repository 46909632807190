body {
  margin: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgb(180, 180, 180) 35%,
    rgb(143, 143, 143) 100%
  );
  background-attachment: fixed;
  background-size: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header-link a {
  color: rgba(0, 0, 0, 0.7);
}

.header-link a:hover {
  color: #dc3545;
  text-decoration: none;
}

.table-max-height-2 {
  height: 100px;
  overflow: scroll;
}

.table-max-height {
  height: 300px;
  overflow: auto;
}

.table-max-height-harvests {
  height: 375px;
  overflow: auto;
}

.table-max-height-citytables {
  height: 255px;
  overflow: auto;
}

.bg-light-transparency {
  background-color: rgba(255, 255, 255, 0.5);
}

.bg-transparent {
  background-color: rgba(255, 255, 255, 0);
}

.bg-transparent ol {
  background-color: rgba(255, 255, 255, 0);
}

.link {
  cursor: pointer;
}

.card-wh {
  max-height: 600px;
  max-width: 600px;
}

.gallery-wh {
  max-height: 350px;
  max-width: 600px;
}
.fc-daygrid-day-frame {
  height: 125px;
}
.fc-popover-body {
  min-height: 100px;
  max-height: 220px;
  overflow: auto;
}
.gallery-wh-2 {
  max-height: 250px;
  max-width: 250px;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 400px;
  height: 1.2em;
  white-space: nowrap;
}

.fc .fc-scrollgrid-section-sticky > * {
  position: relative !important;
}

.navcalendar {
  padding: 0 !important;
}
